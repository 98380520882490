


export default class Contacts{
    TYPE_MAIL = 0;
    TYPE_TEL = 1;
    TYPE_MAP = 2;

    id ="";
    nom ="";
    prenoms ="";
    etc ="";
    fonction ="";
    service ="";
    tel ="";
    mail ="";
    description ="";
    url_picture ="";
    date_creation ="";
    secure =false;
    favorite =false;
    contacts:Type_contact=[];


    constructor(objet) {

        if (typeof objet !=="undefined" && objet !== null){
            this.id =objet.id;
            this.nom =objet.nom;
            this.prenoms =objet.prenoms;
            this.fonction =objet.fonction;
            this.etc =objet.etc;
            this.service =objet.service;
            this.mail = objet.mail;
            this.description =objet.description;
            this.url_picture =objet.url_img;
            this.date_creation =objet.date_creation;
            this.secure =objet.secure;
            this.favorite =objet.favorite;
            this.contacts =objet.contacts;
        }else{
            this.id ="";
            this.nom ="";
            this.prenoms ="";
            this.fonction ="";
            this.etc ="";
            this.service ="";
            this.mail = "";
            this.description ="";
            this.url_picture ="";
            this.date_creation ="";
            this.secure =false;
            this.favorite =false;
            this.contacts =[];
        }

        this.getInitiale = this.getInitiale.bind(this);
        this.getContact = this.getContact.bind(this);

    }

    getContact (typecontact) {
        let mtext ="";
        this.contacts.map((item:Type_contact,index) =>{
            if (item.type_contact === this.TYPE_MAIL && typecontact === this.TYPE_MAIL) mtext = (mtext !=="")?(mtext + "/" + item.valeur_contact ):( item.valeur_contact );
            if (item.type_contact === this.TYPE_TEL && typecontact === this.TYPE_TEL) mtext = (mtext !=="")?(mtext + "/" + item.valeur_contact ):( item.valeur_contact );
        });
        return mtext
    }
    setContact (arraycontact,typecontact){
        arraycontact.map((item) => {
            if(item !=="")this.contacts.push(new Type_contact("",typecontact,item));
        });

    }

    getContactUrl () {
        let prms = new URLSearchParams(this);
        prms.delete("TYPE_MAIL");
        prms.delete("TYPE_TEL");
        prms.delete("TYPE_MAP");
        prms.delete("id");
        prms.delete("contacts");
        let mtext ="";
        this.contacts.map((item:Type_contact,index) =>{
            mtext += (index > 0)?"&":"";
            mtext += "type_contact[]="+item.type_contact+"&valeur_contact[]="+item.valeur_contact;
        });
        return mtext+"&"+prms.toString();
    }

    getInitiale () {
        if (this.nom !=="")
            return this.nom[0].toUpperCase()+""+this.prenoms[0].toUpperCase();
        else
            return "";
    }

    getClass(){
        const randnumber = Math.floor(Math.random() * Math.floor(7));
        let bg ='primary';
        switch (randnumber) {
            case 0: bg = 'primary';break;
            case 1: bg = 'secondary';break;
            case 2: bg = 'success';break;
            case 3: bg = 'danger';break;
            case 4: bg = 'warning';break;
            case 5: bg = 'info';break;
            case 6: bg = 'dark';break;
        }
        return bg
    }
}

export class Type_contact{
    id:"";
    type_contact:"";
    valeur_contact:"";

    constructor(id,type_contact,valeur_contact) {
        this.id = id;
        this.type_contact= type_contact;
        this.valeur_contact=valeur_contact;
    }
}