import React from 'react';
import { PageLoaderProvider } from './PageLoaderContext';
import { PageAlertProvider } from './PageAlertContext';

export default function ContextProviders({ children }) {
    return (
        <PageLoaderProvider>
            <PageAlertProvider>{children}</PageAlertProvider>
        </PageLoaderProvider>
    );
}
