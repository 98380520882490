import {config} from './endpoints';

export const userService = {
    login,
    logout,
    create,
    edit,
    getAll
};



function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: 'login_auth=1&type_action=users&login=' + username + '&passwd=' + password
    };

    return fetch(`${config.apiAuth}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // login successful if there's a user in the response
            if (data) {
                // store user details and basic auth credentials in local storage
                // to keep user logged in between page refreshes
                if (data.out !== 0){
                    data.authdata = data.jwt //window.btoa(username + ':' + password);
                    localStorage.setItem('data', JSON.stringify(data));
                }else{
                    data = null;
                }

            }

            return data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('data');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        //headers: authHeader(),
        mode: 'cors',
        cache: 'default'
    };

    return fetch(`${config.apiUrl}?users=list&type_action=users`,requestOptions)
        .then(res => res.json())

}

function edit(user:User){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        cache: 'default',
        body: 'edit=' + user.id + '&nom=' + user.nom + '&prenoms=' + user.prenoms +'&email='+user.email
    };
    return fetch(`${config.apiUrl}`,requestOptions)
        .then(res => res.json())
}

function create(user:User){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        cache: 'default',
        body: 'create=1&nom=' + user.nom + '&prenoms=' + user.prenoms +'&email='+user.email
    };
    return fetch(`${config.apiUrl}`,requestOptions)
        .then(res => res.json())
}

function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}