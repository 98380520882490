import React from "react";
import {
    Card,
    Col,
    Row,
    CardBody,
    CardHeader,
    Table,
    Form,
    FormGroup, Button,ButtonGroup,
    Label, Input, ModalHeader, ModalBody, ModalFooter, Modal
} from "reactstrap";
import {userService} from "../../services/user.service";
import User from "../../Models/User";

class UsersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userFormnom:'',
            userFormprenoms:'',
            userFormemail:'',
            userCurrent:{},
            user: {},
            users: [],
            usersList:[],
            submitted:false,
            loading:false,
            error:'',
            success:'',
            modal:false,
            isEdit:false

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.editUser = this.editUser.bind(this);
        //this.onItemClickHandler = this.onItemClickHandler.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { userFormnom,userFormprenoms,userFormemail} = this.state;
        // stop here if form is invalid
        if (!(userFormnom && userFormprenoms && userFormemail)) {
            return;
        }

        const userForm = new User(userFormnom,userFormprenoms,userFormemail);
        this.setState({ loading: true, success:'', error:'' });
        if (!this.state.isEdit){
            userService.create(userForm)
                .then(data => {
                    if (data.retour){
                        this.setState({ success: "Insertion réussi !",submitted: false });
                        this.handleReset();
                    }else{
                        this.setState({ error: data.error });
                    }
                    this.GetUsers();
                });
        }else{
            userForm.id = this.state.userCurrent.id;
            userService.edit(userForm)
                .then(data => {
                    if (data.retour){
                        this.setState({ success: "Modification réussi !",submitted: false,isEdit:false });
                        this.handleReset();
                    }else{
                        this.setState({ error: data.error });
                    }
                    this.GetUsers();
                });
        }


    };
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

    }

    handleReset(){
        Array.from(document.querySelectorAll("input")).forEach(
            input => {
                (input.value = "");
                this.setState({ [input.name]: "" });
            }
        );
    };

    componentDidMount() {
        const data = JSON.parse(localStorage.getItem('data'))
        this.setState({
            user: data.user,
            users: [],
            usersList:[],
            loading:true
        });
        this.GetUsers();


    }
    editUser(index){
        this.setState({ isEdit:true });
        let userform = this.state.users[index];
        this.setState({userCurrent: userform })
        Object.entries(userform).forEach(entry => {
            const [key, value] = entry;

            let element =  document.getElementById(key);
            if (typeof(element) != 'undefined' && element != null)
            {

                element.setAttribute("value",value);
                setNativeValue(element, value);
                element.dispatchEvent(new Event('input', { bubbles: true }));
            }
        });

    }
    detailUser(index){

        this.setState({userCurrent: this.state.users[index]})

        this.toggle();

    }
    GetUsers(){
        userService.getAll().then(data => {
            this.setState({
                users: data.users,
                usersList: data.users,
                loading: false
            });

        });
    }
    handleFilter(e){
            const value = e.target.value;

        if (
            !value ||
            value === " " ||
            value === ""
        ){
            this.setState({  usersList: this.state.users });
        }else{
            this.setState({  usersList: this.state.users });
            let usersList = this.state.usersList.filter(
                user => (
                    user.nom.toLowerCase().includes(value.toLowerCase()) ||
                    user.prenoms.toLowerCase().includes(value.toLowerCase())
                )
            );
            this.setState({  usersList: usersList });
        }

    }
    render() {
        const { userFormnom,userFormprenoms,
                userFormemail,userCurrent,isEdit,
                submitted, usersList,success,error,
                loading } = this.state;
        return (
            <Row>
                <Col md={12} lg={7}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md={8} sm={12} className={'pull-left'}>Utilisateurs</Col>
                                <Col md={4} sm={12} className={'pull-right'}>
                                    <Input className={'pull-right'}
                                           type="text"
                                           name="UserFilter"
                                           placeholder={'search'}
                                           onChange={this.handleFilter}
                                    />
                                </Col>
                            </Row>



                        </CardHeader>
                        <CardBody>

                            <Table>
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Nom & Prenom</th>
                                    <th>E-mail</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loading && <tr><td colSpan={4}> Chargement en cours</td></tr>}
                                {(!loading) && usersList.map((user, index) =>
                                    <tr key={user.id}>
                                        <td>{ user.id }</td>
                                        <td>{ user.nom +" "+user.prenoms }</td>
                                        <td>{ user.mail }</td>
                                        <td>
                                            <ButtonGroup>
                                                <button className={"btn btn-xs btn-info"} data-id={index} onClick={() => this.detailUser(index)}>
                                                    <i className={"fa fa-eye"} />
                                                </button>
                                                <button className={"btn btn-xs btn-warning"} data-id={index} onClick={() => this.editUser(index)}>
                                                    <i className={"fa fa-edit"} />
                                                </button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )

                                }
                                </tbody>
                            </Table>
                        </CardBody>

                    </Card>
                </Col>
                <Col md={12} lg={5}>
                    <Card>
                        <CardHeader>{!isEdit ?'Enregistrer':'Modifier'} User</CardHeader>
                        <CardBody>
                            {error &&
                            <p className={'alert alert-danger'}>{error}</p>
                            }
                            {success &&
                            <p className={'alert alert-success'}>{success}</p>
                            }
                            <Form onSubmit={this.handleSubmit}>
                                <FormGroup>
                                    <Label for="nom">Nom</Label>
                                    <Input type="text" name="userFormnom" id="nom" placeholder="Nom"  value={userFormnom} onChange={this.handleChange} className={"userform"}/>
                                    {submitted && !userFormnom &&
                                    <div className="help-block text-danger">Nom is required</div>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label for="prenoms">Prenoms</Label>
                                    <Input type="text" name="userFormprenoms" id="prenoms" placeholder="Prenoms" value={userFormprenoms} onChange={this.handleChange} className={"userform"} />
                                    {submitted && !userFormprenoms &&
                                    <div className="help-block text-danger">Prenoms is required</div>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Mail</Label>
                                    <Input type="email" name="userFormemail" id="mail" placeholder="email" value={userFormemail} onChange={this.handleChange} className={"userform"} />
                                    {submitted && !userFormemail &&
                                    <div className="help-block text-danger">Email is required</div>
                                    }
                                </FormGroup>
                                <button className={!isEdit ?'btn btn-success':'btn btn-warning'}>{!isEdit ?'Enregistrer':'Modifier'}</button>
                                {isEdit &&
                                    <button className={'btn btn-danger'} onClick={() => {this.setState({ isEdit: false })}}>Annuler</button>
                                }
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
                    <ModalBody>
                        <div>
                            {userCurrent &&<div>
                                <div className="pull-left bold all-caps">Nom </div>
                                <div className="pull-right">{ userCurrent.nom }</div>
                                <div className="clearfix"></div>
                                <div className="pull-left bold all-caps">Prenoms </div>
                                <div className="pull-right">{ userCurrent.prenoms }</div>
                                <div className="clearfix"></div>
                                <div className="pull-left bold all-caps">Mail </div>
                                <div className="pull-right">{ userCurrent.mail }</div>
                                <div className="clearfix"></div>
                            </div>

                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Row>
        );
    }


}

function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
        prototypeValueSetter.call(element, value);
    } else {
        valueSetter.call(element, value);
    }
}

export { UsersPage };