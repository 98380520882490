import React from "react";
import { connect } from 'react-redux';
import {contactsService} from "../../services/contact.service";
import {config} from "../../services/endpoints";
import  QrLoader from "../../assets/CustomLoader/Qr"

import {
    Button,
    ButtonGroup,
    Card, InputGroup,
    CardBody,
    CardHeader, InputGroupAddon,
    Col, Form, FormGroup,
    Input, Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Progress,
    Table, CardImg, CardFooter
} from "reactstrap";
import Contacts from "../../Models/Contacts";
import Avatar from "../../vibe_components/utilities/Avatar/Avatar";


const MyLoader = () => <QrLoader />
function Showitem(props) {
    const contact = new Contacts(props.item);
    const index = props.i;
    const ctx = props.ctx;
    return (
        <tr className="warning">
            <td>{contact.id}</td>
            <td className={'text-center'}>{contact.nom +" "+contact.prenoms}</td>
            <td className={'text-center'}>{contact.getContact(contact.TYPE_TEL)}</td>
            <td className={'text-center'}>{contact.getContact(contact.TYPE_MAIL)}</td>
            <td className={'text-center'}>
                <ButtonGroup>
                    <button className={"btn btn-xs btn-info"} data-id={index} onClick={() => ctx.detailContact(index)}>
                        <i className={"fa fa-eye"} />
                    </button>
                    <button className={"btn btn-xs btn-warning"} data-id={index} onClick={() => this.editUser(index)}>
                        <i className={"fa fa-edit"} />
                    </button>
                    <button className={"btn btn-xs btn-secondary"} data-id={index} onClick={() => ctx.toggleFavorite(index)}>
                        { ctx.setImageFavorite(contact.favorite)}
                    </button>
                </ButtonGroup>
            </td>
        </tr>
    );
}
function ModalContact(props) {
    const contact = new Contacts(props.contact);
    const classbg = contact.getClass();

    if (typeof contact.contacts !== "undefined"){
        const str_tel = contact.getContact(contact.TYPE_TEL);
        const str_mail = contact.getContact(contact.TYPE_MAIL);
        const initials = contact.getInitiale();
        const gettingQr = props.gettingQr;
        const getQr = props.getQr;
        return (
            <Modal isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Detail Contact</ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col md={3}>
                                {(contact.url_picture === "" ) &&
                                <Avatar initials={initials} color={ classbg } size="lg" />
                                }
                                {(contact.url_picture !== "" ) &&
                                <CardImg src={config.url_picture + contact.url_picture} className={'avatar avatar-lg'}/>
                                }
                            </Col>
                            <Col md={9}>
                                <address>
                                    <strong>{contact.nom+" "+contact.prenoms}</strong><br/>
                                    {(str_tel !=="") && <span><abbr title="Phone">P :</abbr> <span>{str_tel}</span><br/></span>}
                                    {(str_mail !=="") && <span><abbr title="Email">@ :</abbr> <span>{str_mail}</span><br/></span>}
                                </address>
                            </Col>
                            <Col md={4}>
                                {gettingQr && <MyLoader/>}
                                {(getQr !== null) && <CardImg src={getQr}/>}

                            </Col>

                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.toggle}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
    return '';

}
class ContactsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            CurrentContact: {},
            contacts:[],
            ContactList:[],
            error:'',
            modal:false,
            NomAbonne:'',
            PrenomAbonne:'',
            TelList:[""],
            MailList:[""],
            submitted:false,
            selectedFile: null,
            gettingQr:false,
            getQr:null,
            loaded:0,
            loading:false
        };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.detailContact = this.detailContact.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.setImageFavorite = this.setImageFavorite.bind(this);
    }

    setImageFavorite(fav){
        let classname = 'fa fa-heart-o'
        if ( fav === 1){
            classname ='fa fa-heart';
        }
        return (<i className={classname}/>)
    }
    toggleFavorite(index){
        const ListContact = this.state.ContactList;
        const contactItem = ListContact[index];
        const action = {type: "TOGGLE_FAVORITE", value:contactItem }
        contactsService.toggleFavorite(new Contacts(contactItem))
            .then(data =>{
                this.props.dispatch(action);
                ListContact[index].favorite = (!contactItem.favorite === true )?1:0;
                console.log(ListContact);
                this.setState({
                    ContactList:ListContact
                })
            })

    }
    detailContact(index){
        this.setState({CurrentContact: this.state.ContactList[index],getQr:null,gettingQr:false });
        this.ShowQr(index);
        this.toggle();

    }
    ShowQr(index){
        this.setState({CurrentContact: this.state.ContactList[index],gettingQr:true });
        const contact = new Contacts(this.state.CurrentContact);
        contactsService
            .getQrCode(contact)
            .then((res) =>{
                console.log(res);
                const objectURL = URL.createObjectURL(res);
                this.setState({gettingQr:false, getQr:objectURL });
            });
    }
    onChangeHandler(e){
        this.setState({
            selectedFile: e.target.files[0],
            loaded: 0,
        })
    }
    handleReset(){
        Array.from(document.querySelectorAll("input")).forEach(
            input => {
                (input.value = "");
                this.setState({ [input.name]: "" });
            }
        );
    };

    onUploadProgress(ProgressEvent){
        this.setState({ loaded: (ProgressEvent.loaded / ProgressEvent.total*100) });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { PrenomAbonne,NomAbonne,TelList,MailList,selectedFile} = this.state;
        // stop here if form is invalid
        if (!(PrenomAbonne && NomAbonne)) {
            return;
        }

        const dataFileUpload = new FormData()
        const AbonneForm = new Contacts({});
        AbonneForm.id = 1;
        AbonneForm.nom = NomAbonne;
        AbonneForm.prenoms = PrenomAbonne;
        AbonneForm.contacts = [];
        AbonneForm.setContact(TelList,AbonneForm.TYPE_TEL);
        AbonneForm.setContact(MailList,AbonneForm.TYPE_MAIL);

        dataFileUpload.append('FileUpload',selectedFile)
        dataFileUpload.append('nom',NomAbonne)

        contactsService
            .fileUpload(dataFileUpload,this.onUploadProgress)
            .then((datafile) =>{
                if (datafile.error === 0 ){
                    AbonneForm.url_picture = datafile.file;

                    contactsService.create(AbonneForm)
                        .then((data) =>{
                            if (data.retour === 1){
                                this.setState({ submitted: false });
                                this.handleReset();
                            }
                            this.GetContacts();
                        })
                }
            });


    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

    }
    handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const type = e.target.attributes['data-obj'].value;

        const { TelList,MailList } = this.state;
        if (type ==="email"){
            MailList[index] = value;
            this.setState({MailList:MailList});
        }else{
            TelList[index] = value;
            this.setState({TelList:TelList});
        }

    };

    handleRemoveClick = (index,type) => {
        const { TelList,MailList } = this.state;
        if (type ==="email"){
            MailList.splice(index, 1);
            this.setState({MailList:MailList});
        }else{

            TelList.splice(index, 1);
            this.setState({TelList:TelList});
        }

    };

    handleAddClick = (e) => {
        const { TelList,MailList } = this.state;
        const type = e.target.attributes['data-obj'].value;
        const index = e.target.attributes['data-index'].value;
        const i = (parseInt(index) === 0)?0:(index-1);
        if (type ==="email"){

            if(MailList[i] !==""){
                MailList.push('');
                this.setState({MailList:MailList});
            }

        }else{
            if(TelList[i] !==""){
                TelList.push('');
                this.setState({TelList:TelList});
            }
        }

    };

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    componentDidMount() {
        const data = JSON.parse(localStorage.getItem('data'))
        this.setState({
            user: data.user,
            contacts: [],
            ContactList:[],
            error:'',
            CurrentContact:new Contacts(null),
            loading:true
        });

        this.GetContacts();


    }
    GetContacts(){
        contactsService
            .getAll()
            .then(data => {
            this.setState({
                contacts:data.contacts,
                ContactList:data.contacts,
                error:'',
                loading:false
            })
        }).catch(error =>{

            this.setState({
                error:error,
                contacts:[],
                ContactList:[],
            })
        });
    }


    render() {
        const { loading,error, ContactList,TelList,MailList,CurrentContact,
            submitted, NomAbonne, PrenomAbonne,selectedFile,gettingQr,getQr

        } = this.state;
        const ctx = this;

        return (
            <Row>
                <Col md={12} lg={7}>
                    <Card>
                        <CardHeader>
                            <Row>

                                <Col md={8} sm={12} className={'pull-left'}>Contacts</Col>
                                <Col md={4} sm={12} className={'pull-right'}>
                                    <Input className={'pull-right'}
                                           type="text"
                                           name="UserFilter"
                                           placeholder={'search'}
                                           onChange={this.handleFilter}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom & Prenom</th>
                                    <th>Tel</th>
                                    <th>E-mail</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {loading && <tr><td colSpan={5} className={'text-center'}> Chargement en cours</td></tr>}
                                    {(error !=='') && <tr><td colSpan={5} className={'text-center'}> { error }</td></tr>}
                                    {(!loading) && ContactList.map(
                                        (item:Contacts, index) => (<Showitem key={index} item={item} ctx={ctx} i={index}/>)
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>

                    </Card>
                </Col>
                <Col md={12} lg={5}>
                    <Card>
                        <CardHeader> Formulaire </CardHeader>
                        <CardBody>
                            <Form onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col sm={12} md={8} className={'offset-md-2'}>
                                        <FormGroup className={'files color'}>
                                            <label>Upload Your File </label>
                                            <input type="file" name="file" className={'form-control'} onChange={this.onChangeHandler}/>
                                            <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
                                            {submitted &&  (selectedFile === null) &&
                                            <div className="help-block text-danger">Charger un fichier !</div>
                                            }
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <FormGroup>
                                    <Label for="nom">Nom</Label>
                                    <Input type="text" name="NomAbonne" id="nom" placeholder="Nom"  value={NomAbonne} onChange={this.handleChange} className={"userform"}/>
                                    {submitted && !NomAbonne &&
                                    <div className="help-block text-danger">Nom is required</div>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label for="prenoms">Prenoms</Label>
                                    <Input type="text" name="PrenomAbonne" id="prenoms" placeholder="Prenoms" value={PrenomAbonne} onChange={this.handleChange} className={"userform"} />
                                    {submitted && !PrenomAbonne &&
                                    <div className="help-block text-danger">Prenoms is required</div>
                                    }
                                </FormGroup>

                                <div className={'row'}>

                                    {
                                        TelList.map((x,i) => {
                                            return (
                                                <Col md={8} className={'offset-md-2'} key={i}>
                                                    <FormGroup className={'LigneTel'} >
                                                        <Label for={'Tel'+i} >Tel</Label>
                                                        <InputGroup>

                                                            <Input
                                                                id={'Tel'+i}
                                                                name={'Tel'}
                                                                value={x}
                                                                onChange={e => this.handleInputChange(e, i)} data-obj={'tel'}/>
                                                            <InputGroupAddon addonType="append">
                                                                {TelList.length !== 1 && <button type={'button'} className={'btn btn-danger'} onClick={() => this.handleRemoveClick(i)} data-obj={'tel'}>Remove</button>}
                                                                {TelList.length - 1 === i && <button type={'button'} className={'btn btn-info'} onClick={(e) => this.handleAddClick(e)} data-obj={'tel'} data-index={i}>Add</button>}
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }
                                </div>



                                <div className={'row'}>

                                    {
                                        MailList.map((x,i) => {
                                            return (
                                                <Col md={8} className={'offset-md-2'}  key={i}>
                                                    <FormGroup className={'LigneTel'}>
                                                        <Label for={'Mail'+i} >E-Mail</Label>
                                                        <InputGroup>

                                                            <Input
                                                                id={'Mail'+i}
                                                                name={'Mail'}
                                                                value={x}
                                                                onChange={e => this.handleInputChange(e, i)} data-obj={'email'} type={'email'}/>
                                                            <InputGroupAddon addonType="append">
                                                                {MailList.length !== 1 && <button type={'button'} className={'btn btn-danger'} onClick={() => this.handleRemoveClick(i)} data-obj={'email'}>Remove</button>}
                                                                {MailList.length - 1 === i && <button type={'button'} className={'btn btn-info'} onClick={this.handleAddClick} data-obj={'email'} data-index={i}>Add</button>}
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }
                                </div>
                                <div className={'text-center'}>
                                    <button className={'btn btn-success'}> Enregistrer </button>
                                </div>


                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <ModalContact isOpen={this.state.modal} toggle={this.toggle} contact={CurrentContact} gettingQr={gettingQr} getQr={getQr}/>
            </Row>

        )
    }

}
const mapStateToProps = (state) => {
    return {
        FavoriteContact: state.FavoriteContact
    }
}


export default connect(mapStateToProps)(ContactsPage);