
const initialState = {FavoriteContact: []}
function toggleFavorite(state = initialState ,action) {
    let nextState;
    switch (action.type) {

        case 'TOGGLE_FAVORITE':
            const favoriteContactIndex = state.FavoriteContact.findIndex(contact =>contact.id === action.value.id)
            if (favoriteContactIndex !==-1){
                nextState = {
                ...state,
                FavoriteContact: state.FavoriteContact.filter( (contactItem,index) => index !== favoriteContactIndex)
            }
            }else{
                nextState = {
                    ...state,
                    FavoriteContact: [...state.FavoriteContact, action.value]
                }
            }
            console.log(nextState.FavoriteContact);
            return nextState || state;
        default:
            return state;
    }
}

export default toggleFavorite;