import HomePage from "./HomePage/HomePage";
import {UsersPage} from "./UsersPage";
import ContactsPage from "./ContactsPage/ContactsPage";


const pageList = [
    {
        name: 'Accueil',
        path: '/home',
        component: HomePage,
    },
    {
        name: 'Liste des utilisateurs',
        path: '/users',
        component: UsersPage,
    },
    {
        name: 'Liste des contacts',
        path: '/Contacts',
        component: ContactsPage,
    }
];

export default pageList;