import {config} from "./endpoints";
import { Type_contact, Contacts } from "../Models/Contacts";
import axios from 'axios';

export const contactsService = {
    getAll,
    fileUpload,
    create,
    toggleFavorite,
    getQrCode
};

function getQrCode(abonne:Contacts){
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        cache: 'default'
    };

    return fetch(`${config.apiUrl}?getQrFor=`+abonne.id+`&type_action=contacts`,requestOptions)
        .then(res => {return res.blob();})

}
function toggleFavorite(abonne:Contacts){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        cache: 'default',
        body: "setFav="+abonne.id+"&type_action=contacts&value="+!abonne.favorite
    };

    return fetch(`${config.apiUrl+"?type_action=contacts"}`,requestOptions)
        .then(res => res.json())
}
function getAll() {
    const requestOptions = {
        method: 'GET',
        //headers: authHeader(),
        mode: 'cors',
        cache: 'default'
    };

    return fetch(`${config.apiUrl}?list=1&type_action=contacts`,requestOptions)
        .then(handleResponse)
        .then(res => res)
        .catch((error) => {
            console.log(error);
            return error;
        });

}

function create(abonne:Contacts){
    const prm = abonne.getContactUrl();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        cache: 'default',
        body: 'create=1&'+prm
    };

    return fetch(`${config.apiUrl+"?type_action=contacts"}`,requestOptions)
        .then(res => res.json())
}

function fileUpload(dataToUpload,onUploadProgress) {

    return axios
        .post(`${config.apiUrl+"?type_action=contacts"}`,dataToUpload,{
            onUploadProgress: onUploadProgress
        })
        .then(res =>{
            return(res.data);
        });
}

function handleResponse(response) {
        if (response.ok) {
            return response.json();
        } else {
            debugger;
            throw new Error('Something went wrong');
        }
}