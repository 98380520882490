export default {
    top: [
        {
            name: 'Home',
            url: '/home',
            icon: 'Home',
        },
        {
            name: 'Users',
            url: '/Users',
            icon: 'users',
        },{
            name: 'Contacts',
            url: '/Contacts',
            icon: 'contacts',
        },
        {
            name: 'Pages',
            icon: 'File',
            children: [
                {
                    name: 'Blank',
                    url: '/pages/blank',
                },
                {
                    name: 'Sub Navigation',
                    url: '/pages/subnav',
                },
                {
                    name: '404',
                    url: '/pages/404',
                },
            ],
        },

    ],
    bottom: [],
};
