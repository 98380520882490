
export default class User {
    nom ='';
    prenoms ='';
    email ='';
    pass ='';
    login ='';
    id ='';

    constructor(nom: string, prenoms: string, email: string) {
        this.nom = nom;
        this.prenoms = prenoms;
        this.email = email;
    }
}