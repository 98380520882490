import React from 'react';
import { BrowserRouter as Router,Redirect, Route } from 'react-router-dom';

import { PrivateRoute } from '../components';
import { LoginPage } from '../Pages/LoginPage';
import DashboardLayout from "../layouts/DashboardLayout";
import routes from "../Pages";
import { Provider } from 'react-redux';
import configureStore from "../store/configureStore";

class App extends React.Component {
    render() {
        return (
                <Provider store={ configureStore}>
                    <Router>
                        {routes.map((page, key) => (
                            <PrivateRoute path={page.path} component={DashboardLayout} key={key} />
                        ))}

                        <Route path="/login" component={LoginPage} />
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                    </Router>
                </Provider>

        );
    }
}

export { App };