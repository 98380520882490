import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ContextProviders from '../helpers/ContextProviders';
import { SidebarNav, Page, Header, PageContent , PageAlert, Footer,  Avatar } from "../vibe_components/";
import Logo from "../logo.svg";
import nav from "../_nav";
import routes from '../Pages';
import handleKeyAccessibility,{ handleClickAccessibility } from "../helpers/handleKeyAccessibility";



const MOBILE_SIZE = 992;


export default class DashboardLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebarCollapsed: false,
            isMobile: window.innerWidth <= MOBILE_SIZE,
            showChat1: true,
        };
    }

    handleResize = () => {
        if (window.innerWidth <= MOBILE_SIZE) {
            this.setState({ sidebarCollapsed: false, isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    };

    toggleSideCollapse = () => {
        this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
    };

    componentDidUpdate(prev) {
        if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
            this.toggleSideCollapse();
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('keydown', handleKeyAccessibility);
        document.addEventListener('click', handleClickAccessibility);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { sidebarCollapsed } = this.state;
        const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

        return (
            <ContextProviders>
                <div className={`app ${sidebarCollapsedClass}`}>
                    <PageAlert />
                    <div className="app-body">
                    <SidebarNav
                        nav={nav}
                        logo={Logo}
                        logoText="APPWAS."
                        isSidebarCollapsed={sidebarCollapsed}
                        toggleSidebar={this.toggleSideCollapse}
                        {...this.props}
                    />
                    <Page>
                        <Header
                            toggleSidebar={this.toggleSideCollapse}
                            isSidebarCollapsed={sidebarCollapsed}
                            routes={routes}
                            {...this.props}
                        >
                            <HeaderNav />
                        </Header>
                        <PageContent>
                            <Switch>
                                {routes.map((page, key) => (
                                    <Route path={page.path} component={page.component} key={key} />
                                ))}
                                <Redirect from="/" to="/home" />
                            </Switch>
                        </PageContent>
                    </Page>
                </div>
                    <Footer>
                        <span>Copyright © 2019 Nice Dash. All rights reserved.</span>
                        <span>
                          <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
                        </span>
                                    <span className="ml-auto hidden-xs">
                          Made with{' '}
                                        <span role="img" aria-label="taco">
                            🌮
                          </span>
                        </span>
                    </Footer>
                </div>
            </ContextProviders>
        );
    }
}
function HeaderNav() {
    return (
        <React.Fragment>
            <NavItem>
                <form className="form-inline">
                    <input className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />
                    <Button type="submit" className="d-none d-sm-block">
                        <i className="fa fa-search" />
                    </Button>
                </form>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    New
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>Project</DropdownItem>
                    <DropdownItem>User</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        Message <Badge color="primary">10</Badge>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                    <Avatar size="small" color="blue" initials="JS" />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>Option 1</DropdownItem>
                    <DropdownItem>Option 2</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </React.Fragment>
    );
}