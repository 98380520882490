import React from 'react';

import { userService } from '../../services';
import  Loader  from '../../assets/Loader/Loader';
import {Container, Row,Col} from "reactstrap";
class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        userService.logout();

        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password, returnUrl } = this.state;

        // stop here if form is invalid
        if (!(username && password)) {
            return;
        }

        this.setState({ loading: true });
        userService.login(username, password)
            .then(
                user => {
                    if (user !== null && user !== ""){
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    }else{
                        this.setState({ error:'Username or password is incorrect', loading: false })
                    }

                },
                error => this.setState({ error, loading: false })
            );
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
                <Container>
                    <Row>
                        <Col sm={12} md={6} className={'offset-md-3'}>
                            <form className={"form-signin"} name="form" onSubmit={this.handleSubmit}>
                                <div className="text-center mb-4">

                                    <img src="https://busy.mayashop.ovh/assets/img/logo-small.png" alt=""/>
                                    <h2>LAW WEB APPLICATION</h2>
                                </div>
                                <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                    <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                                    {submitted && !username &&
                                    <div className="help-block text-danger">Username is required</div>
                                    }
                                </div>

                                <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                    <label htmlFor="password">Password</label>
                                    <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                    {submitted && !password &&
                                    <div className="help-block text-danger">Password is required</div>
                                    }
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-lg btn-primary btn-block" disabled={loading}>{ !loading && <span>Login</span>}
                                        {loading &&
                                        <Loader type="spin" />
                                        }
                                    </button>
                                </div>
                                {error &&
                                <div className={'alert alert-danger'}>{error}</div>
                                }
                            </form>
                        </Col>
                    </Row>

                </Container>

        );
    }
}

export { LoginPage };