import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userService } from '../../services';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            users: []
        };
    }

    componentDidMount() {
        const data = JSON.parse(localStorage.getItem('data'))
        this.setState({
            user: data.user,
            users: { loading: true }
        });
        //userService.getAll().then(users => this.setState({ users }));


    }

    render() {
        const { user } = this.state;
        const favContacts = this.props.FavoriteContact;
        return (
            <div className="col-md-12 ">
                <h1>Hi {user.prenoms + " "+ user.nom}!</h1>
                <p>You're logged in with React & Basic HTTP Authentication!!</p>
                <h3>Favorite Contacte:</h3>

                {favContacts.length &&
                <ul>
                    {favContacts.map((contact, index) =>
                        <li key={contact.id}>
                            {contact.nom + ' ' + contact.prenoms}
                        </li>
                    )}
                </ul>
                }
                <p>
                    <Link to="/login">Logout</Link>
                </p>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        FavoriteContact: state.FavoriteContact
    }
}
//export { HomePage };
export default connect(mapStateToProps)(HomePage);